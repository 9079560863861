import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useSwipeable } from "react-swipeable";
import { useStore } from "@nanostores/react";
import { isCartOpen, cartData } from "@stores/cartStore";
import { checkoutData } from "@stores/checkoutStore";
import {
	createCart,
	updateCart,
	updateCartDiscountCodes,
	updateCartNote,
	updateCartAttributes,
	addToCart,
	getCart,
} from "@api/shopify";


import { addUrlParameter } from "@utils/utils";

import Gift from "@components/Icons/Gift.jsx";
import ThickX from "@components/Icons/ThickX.jsx";
import ShoppingBag from "@components/Icons/ShoppingBag.jsx";
import Tag from "@components/Icons/Tag.jsx";
import FreeProduct from '@components/Cart/FreeProduct.jsx'

import {
    handleQuantityChange,
    handleRemoveDiscount,
    handleCheckout,
    manageDiscounts,
    handleRemoveItem,
    handleNoteSubmit,
    updateCartTrackingAttributes,
} from './cartUtility.jsx'

const ShippingRemainder = React.lazy(() =>
	import("@components/Cart/ShippingRemainder.jsx")
);

const Cart = () => {
	let $isCartOpen = useStore(isCartOpen || false);
	let $cartData = useStore(cartData || null);
	let $checkoutData = useStore(checkoutData || null);

	let cartId = $cartData.cartId || null;

	const [cartStoreData, setCartStoreData] = useState();

	const [discount, setDiscount] = useState("");
	const [cartViewTracked, setCartViewTracked] = useState(false);
  const [data, setData] = useState({})
	const [cartTotals, setCartTotals] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [discountAmount, setDiscountAmount] = useState(0);
	const [subtotalBeforeDiscount, setSubtotalBeforeDiscount] = useState(0);
	const [giftWrap, setGiftWrap] = useState(
		$cartData && $cartData.giftWrap && $cartData.giftWrap !== "undefined"
			? $cartData.giftWrap
			: false
	);
	const [isGiftNoteOpen, setIsGiftNoteOpen] = useState(false);
	const [discountMessage, setDiscountMessage] = useState("");
	const [giftNote, setGiftNote] = useState($cartData ? $cartData.giftNote : "");


	// console.log('$cartData in Cart.jsx', $cartData)

	const timeOptions = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		timeZoneName: "short",
	};

	let items = [];
	let cost;
	let subTotal;
	let linesTotal;
	let linesTotalDiscount;
	let numberOfEmbroideredItems;
	let embroideryTotal;
	let subtotalBeforeEmbroidery;
	let totalAmount;
	let total;
	let subtotalAmount;
	let discounts;
	let automaticDiscountCodes;
	let shippingRemainder;
    let freeProduct;

	let discountedAmount;
	let subtotalBeforeD;

	let allClasses = false;

	const handlCartAddToCart = async (e) => {
		console.log('e in handlCartAddToCart', e)

		// Example payload
		// {
		// 	item: {
		// 		category: "Kids Apparel.Babies.Unisex.Sleepwear.Robe",
		// 		details: {
		// 			'0-6m': {
		// 				id: { numberLong: '40123816247358' },
		// 				quantity:1,
		// 			}
		// 		}
		// 		image_url:"https://pdimg-prod-fmv3.findmine.com/v3/eae8a76c-ff39-46cf-b0c7-3375e669ac2f/df7559bc-1e4f-4368-bb9a-7c4a694ed680.lg.png",
		// 		item_id:"eae8a76c-ff39-46cf-b0c7-3375e669ac2f",
		// 		item_url:"https://checkout.monicaandandy.com/products/terry-bathrobe?variant=40123816214590",
		// 		price:3600,
		// 		title:"Terry Bathrobe",
		// 		uni:"7186121556030||None",
		// 	}
		// 	selectedOption:"40123816247358" // Optional
		// }

		let cartId = $cartData.cartId
		let item = e?.item
		let variantId = item?.item_url?.split('variant=')[1]
		if (!variantId) return
		variantId = e.selectedOption ? e.selectedOption : variantId;

		const product = `gid://shopify/ProductVariant/${variantId}`
		let lines

		lines = {
				quantity: 1,
				merchandiseId: product,
		}



		// console.log('cartId exists', cartId, cartData)

		addToCart(cartId, lines).then((addToCartData) => {
				console.log('addToCartData cart already exists', addToCartData)

				let cartLines =
						addToCartData.body?.cartLinesAdd?.cart?.lines?.edges
				let cartCost = addToCartData.body?.cartLinesAdd?.cart?.cost

				let cartObj = {
						...cartData.get(),
						lines: cartLines,
						cost: cartCost,
				}

				cartData.set(cartObj)
				// setIsLoading(false)
				isCartOpen.set('is_cart_open', true)
				// clearOptions()

				// if (sizes.length > 0) {
				// 		setCurrentSize({
				// 				size: '',
				// 				variantId: '',
				// 				sku: '',
				// 		})
				// } else {
				// 		setCurrentSize({
				// 				size: 'nosize',
				// 				variantId:
				// 						initialVariant &&
				// 						initialVariant[0] &&
				// 						initialVariant[0].id,
				// 				sku:
				// 						initialVariant &&
				// 						initialVariant[0] &&
				// 						initialVariant[0].sku,
				// 		})
				// }
				// setQuantityDisabled(false)

				// dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
				// dataLayer.push({
				// 		event: 'dl_add_to_cart',
				// 		page: 'pdp',
				// 		ecommerce: {
				// 				currency: 'USD',
				// 				value: currentPrice?.price,
				// 				items: [
				// 						{
				// 								item_id: variantId,
				// 								item_name: productTitle,
				// 								affiliation: 'Vercel',
				// 								item_brand: 'Monica + Andy',
				// 								item_category: currentColor ? currentColor : '',
				// 								item_category2: currentSize?.size,
				// 								item_variant: currentSize?.variantId,
				// 								price: currentPrice?.price,
				// 								quantity: quantity,
				// 						},
				// 				],
				// 		},
				// })

				// va.track('PDP Add To Cart', {
				// 		productName: productTitle,
				// 		productSku: currentSize?.sku,
				// 		productPrice: currentPrice?.price,
				// 		productQuantity: quantity,
				// 		productVariant: currentSize?.variantId,
				// 		productColor: currentColor ? currentColor : '',
				// 		productSize: currentSize?.size,
				// })
		})
}

	const loadFindMineCartWidget = () => {
		const scriptTag = document.createElement("script");
		scriptTag.src = "https://js.findmine.com/482C58EA8E399A59E7A4.module.js";
		scriptTag.addEventListener("load", () => {
			setLoaded(true);
		});
		document.body.appendChild(scriptTag);
	};

	const matchFindMineItem = (pid, price) => {
		window.FindMineCart.match({
			application: "17DDBA6FE3F1E1CC7934",
			product_id: `${pid}`,
			product_price: price,
			product_in_stock: true,
			return_pdp_item: true,
			event_handlers: {
				onItemClick: (e) => {
						console.log('cart widget click payload:', e);
						// @TODO - use this to update the cart
						handlCartAddToCart(e)
				},
			}
		});
		window.FindMineCart.lastCartParams = pid;
	};

	useEffect(() => {
		if (!loaded) {
			// Load the FindMine cart widget
			loadFindMineCartWidget();
		} else if ($cartData.lines?.length > 0) {
			// Match the FindMine cart widget
			const [lastItem] = $cartData.lines.slice(-1);
			let attributes = lastItem?.node?.attributes;
			// @TODO: Find a more reliable way to get the PID of the last item.
			let lastItemId = attributes && attributes.length > 0 && attributes[0].key === '_pid' ? attributes[0].value : null;
			if (!lastItemId) return;
			let price = lastItem?.node?.cost?.amountPerQuantity?.amount;
			console.log('lastItemId', lastItemId, lastItem, $cartData);
			if (lastItemId && window.FindMineCart && lastItemId !== window.FindMineCart.lastCartParams) {
					// Do match call
					matchFindMineItem(lastItemId, price);
			}
		}
		console.log('cartData & loaded useEffect', $cartData.lines?.length > 0)
	}, [$cartData, loaded]);

	useEffect(() => {
		if ($isCartOpen && $cartData && !cartViewTracked) {
				chord.trackCartViewed({ cart: $cartData })
				updateCartTrackingAttributes(cartId)
				setCartViewTracked(true)
		} else if (!$isCartOpen && cartViewTracked) {
				setCartViewTracked(false)
		}
}, [$cartData, $isCartOpen, cartViewTracked]);

	useEffect(() => {
		if ($cartData) {
			console.log('cartdata', $cartData);
			subtotalAmount = $cartData.cost?.subtotalAmount?.amount;
			totalAmount = $cartData.cost?.totalAmount?.amount;

            const invalidProductTypes = [
                'Gift Cards',
                'Gift Card',
                'Specialty SKU',
                'Embroidery Option',
                'Embroidery',
                'Virtual Classes',
                'Classes + Events',
                'Nursery',
                'Cribs',
                'Classes',
                'Strollers',
            ]

			let items = $cartData.lines;

            let subTotal
            let subtotal
            let shippingRemainder
            let applicableLines

			if (items && items.length > 0) {
				linesTotal = items.reduce((acc, item) => {
					let costPerItem = item.node.cost.subtotalAmount.amount;
					return acc + parseFloat(costPerItem);
				}, 0);

                applicableLines = items.filter((line) => {
                    const item = line.node
                    const productType = item?.merchandise?.product?.productType
                    const vendor = item?.merchandise?.product?.vendor
                    return (
                        !invalidProductTypes.includes(productType) &&
                        vendor === 'Monica + Andy'
                    )
                })

                if (applicableLines && applicableLines.length > 0) {
                    subtotal = applicableLines.reduce(function (
                        previousValue,
                        currentValue
                    ) {
                        // console.log('previousValue, currentValue', previousValue, currentValue, typeof previousValue, typeof currentValue)
                        return (
                            parseFloat(previousValue) +
                            parseFloat(currentValue.node.cost.totalAmount.amount)
                        )
                    },
                    0)
                }

                if (subtotal) {
                    subTotal = parseFloat(subtotal).toFixed(2) || 0
                    freeProduct = parseFloat(100 - subTotal).toFixed(2) || 0
                }

				// console.log('linesTotal', linesTotal)

                let $newCartData = cartData.get()


                setData({
                    ...$newCartData,
                    subTotal: subTotal,
                    freeProduct: freeProduct,
                })

				numberOfEmbroideredItems = items.filter((item) => {
					return (
						item.node.merchandise.product.title.indexOf("Embroidery Option") >
						-1
					);
				}).length;

				// console.log('numberOfEmbroideredItems', numberOfEmbroideredItems)

				if (numberOfEmbroideredItems > 0) {
					embroideryTotal = parseFloat(numberOfEmbroideredItems * 15).toFixed(
						2
					);
					subtotalBeforeEmbroidery = parseFloat(
						linesTotal - embroideryTotal
					).toFixed(2);
				}

				// console.log(
				//     'embroideryTotal, subtotalBeforeEmbroidery',
				//     embroideryTotal,
				//     subtotalBeforeEmbroidery
				// )

				const check = "Classes + Events";

				allClasses = items.every(
					(item) => item.node?.merchandise?.product?.productType === check
				);

				// console.log('allClasses', allClasses)
			}

			if (subtotalAmount && totalAmount && subtotalAmount !== totalAmount) {
				discountedAmount = totalAmount - subtotalAmount;
				subtotalBeforeD = totalAmount;
				total = subtotalAmount;
				setCartTotals({
					subtotal: parseFloat(subtotalAmount).toFixed(2),
					total: totalAmount,
					linesTotal: parseFloat(linesTotal).toFixed(2),
					linesTotalDiscount: linesTotalDiscount,
					discountedTotal: linesTotal - linesTotalDiscount,
					discount: discountedAmount,
					embroideryTotal: embroideryTotal,
					subtotalBeforeEmbroidery: subtotalBeforeEmbroidery,
				});
			} else {
				setCartTotals({
					subtotal: parseFloat(subtotalAmount).toFixed(2),
					total: totalAmount,
					linesTotal: parseFloat(linesTotal).toFixed(2),
					linesTotalDiscount: linesTotalDiscount,
					discountedTotal: linesTotal - linesTotalDiscount,
					discount: 0,
					embroideryTotal: embroideryTotal,
					subtotalBeforeEmbroidery: subtotalBeforeEmbroidery,
				});
			}
			setGiftNote($cartData ? $cartData.giftNote : "");
			setDiscountAmount(discountedAmount);
		}

		setGiftWrap($cartData.giftWrap ?? false);
		setCartStoreData($cartData);


	}, [$cartData]);

	useEffect(() => {
		if ($checkoutData && $checkoutData.checkout === true) {
			getCart(cartId).then((getCartData) => {
				// console.log('getCartData in useEffect checkout', getCartData)
				if (getCartData && getCartData?.body?.cart !== null) {
					let cartLines = getCartData?.body?.cart?.lines?.edges;
					let cartCost = getCartData?.body?.cart?.cost;
					let discountCodes = getCartData?.body?.cart?.discountCodes;
					// // console.log cartLines, cartCost', cartLines, cartCost)

					let cartObj = {
						...cartData.get(),
						lines: cartLines,
						cost: cartCost,
						discountCodes: discountCodes,
					};

					cartData.set(cartObj);
					setCartStoreData(cartObj);
				} else {
					// console.log(
					//     'getCartData.body.cart; cart is null',
					//     getCartData.body.cart
					// )
					cartData.set({});
					setCartStoreData({});
				}
			});
		}
	}, [$checkoutData]);

	useEffect(() => {
		if ($cartData && $cartData.lines?.length > 0 && $isCartOpen) {
			let items = $cartData.lines;
			let totalAmount = $cartData.cost?.totalAmount?.amount;
			let gtmItems = items.map((item) => {
				// console.log("item in gtmItems", item);
				let product = item.node.merchandise.product;
				let productQuantity = item.node.quantity;
				let options = item.node.merchandise.selectedOptions;
				let id = item.node.merchandise.id;
				let price = item.node.cost.subtotalAmount.amount;

				id = id.split("/").pop();

				return {
					item_id: id,
					item_name: product.title,
					affiliation: "Vercel",
					item_brand: product.vendor,
					item_category: options[0]?.value || "",
					item_category2: options[1]?.value || "",
					item_variant: item?.node?.merchandise?.title
						? item?.node?.merchandise?.title
						: "",
					price: price,
					quantity: productQuantity ? productQuantity : 1,
				};
			});

			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			dataLayer.push({
				event: "dl_view_cart",
				ecommerce: {
					items: gtmItems,
					currency: "USD",
					value: totalAmount,
				},
			});
		}
	}, [$cartData, $isCartOpen]);

	const handleDiscountInput = (e) => {
		setDiscount(e.target.value);
	};

	const handleApplyDiscount = async () => {
		if (discount) {
			let discountHandle = await manageDiscounts(cartId, discount);
			setDiscountMessage(discountHandle);
			setDiscount("");
		}

		setTimeout(() => {
			setDiscountMessage("");
		}, 5000);
	};

	const handleGiftWrap = (e) => {
		// console.log('e in handleGiftWrap', e)
		let checked = e.target.checked;
		let cartObj = {};
		let checkoutUrl = cartData.get().checkoutUrl;
		let giftNote = cartData.get().giftNote;
		// console.log("giftNote", giftNote);
		let newUrl = "";
		if (checkoutUrl) {
			newUrl = addUrlParameter(checkoutUrl, "giftWrap", checked, true);
		}
		// console.log('newUrl in handleGiftWrap', newUrl, checked)
		if (e && e.target && e.target.checked) {
			setGiftWrap(true);
			cartObj = {
				...cartData.get(),
				giftWrap: checked,
				checkoutUrl: newUrl,
			};
			cartData.set(cartObj);
			setCartStoreData(cartObj);

			let attributes = [
				{
					key: "gift_wrapping",
					value: "Yes",
				},
				{
					key: "gift_note",
					value: giftNote,
				},
			];

			handleAttributeChange(cartId, attributes);
		} else {
			setGiftWrap(false);
			setIsGiftNoteOpen(false);
			cartObj = {
				...cartData.get(),
				giftWrap: checked,
				giftNote: "",
				checkoutUrl: newUrl,
			};
			cartData.set(cartObj);
			setCartStoreData(cartObj);
			setGiftNote("");

			let attributes = [{}];

			handleAttributeChange(cartId, attributes);
		}
	};

	const handleGiftNote = (e) => {
		let giftNoteText = e.target.value;

		// console.log("giftNoteText in handleGiftNote", giftNoteText);
		if (!giftNoteText.trim()) {
			giftNoteText = "";
		}
		setGiftNote(giftNoteText);
	};

	const nullFuction = () => {};

	const handleSwiped = (e) => {
		// console.log("e", e);

		if (e.dir === "Right") {
			isCartOpen.set(false);
		}
	};

	const handlers = useSwipeable({
		onSwiped: handleSwiped,

		touchEventOptions: { passive: false },
		// preventScrollOnSwipe: true,
		trackMouse: true,
	});




	return (
		<aside
			{...handlers}
			id="cart-drawer"
			className={`cart-drawer h-full overflow-y-auto bg-white ${
				$isCartOpen ? "translate-x-0" : "translate-x-[100%]"
			} fixed top-0 right-0 z-50 ease-in-out duration-300`}
		>
			<div
				className={`cart-outer w-full max-w-[100vw] lg:max-w-[450px] lg:w-[450px] bg-white border border-slate-100 h-full min-h-[600px] ${
					$isCartOpen ? "cart-open" : ""
				}`}
			>
				<div className="cart-inner relative">
					<span
						className="close-x text-4xl font-normal text-ash/70 absolute top-0 right-3 cursor-pointer hover:text-ash z-50 inline-block"
						tabIndex="0"
						onClick={() => isCartOpen.set(false)}
					>
						&times;
					</span>
					<div className="cart-header-outer">
						<div className="cart-header-inner flex items-center justify-center text-center p-3">
							<div className="cart-header-icon mr-2">
								<ShoppingBag width="w-[21px]" />
							</div>
							<h2>Shopping Cart</h2>
						</div>
						<ShippingRemainder />
					</div>

					<div className="cart-body-outer">
						<div className="cart-body-inner p-3">
							<div className="cart-body-header flex flex-col mb-4">
								{cartStoreData &&
								cartStoreData.lines &&
								cartStoreData.lines.length > 0 ? (
									<div className="cart-discount flex my-2 w-full">
										<label htmlFor="discount-input" className="flex flex-col w-full">
											Discount Code
											<input
												className="bg-slate/50 border border-slate-200 px-2 h-8 w-[96%] mr-2"
												type="text"
												id="discount-input"
												value={discount}
												onChange={(e) => handleDiscountInput(e)}
											/>
										</label>
										<button
											className="h-8 flex items-center justify-center bg-flint text-white w-[100px] relative top-[24px] text-sm hover:bg-ash ml-3"
											onClick={handleApplyDiscount}
										>
											Apply
										</button>
									</div>
								) : null}
								{discountMessage && discountMessage.length > 0 ? (
									<span className="text-red-600 text-normal text-[12px]">
										{discountMessage}
									</span>
								) : null}
								<div className="discount-codes-wrapper flex gap-1 mt-2 mb-4">
									{cartStoreData &&
									cartStoreData.discountCodes &&
									cartStoreData.discountCodes.length > 0 ? (
										<div className="discount-codes flex">
											{cartStoreData.discountCodes.map((discount, i) => {
												// console.log(
												//     'discount in cart store data',
												//     discount
												// )
												if (discount.applicable) {
													return (
														<span
															className="discount-code py-1 px-2 bg-slate text-flint border-2 border-dashed border-oat text-xs flex items-center justify-start"
															key={discount.code || i}
														>
															<span className="tag-wrap relative mr-1">
																<Tag width="w-[12px]" />
															</span>
															{discount.code}{" "}
															<span
																className="ml-2 cursor-pointer hover:text-red-500 text-lg leading-none relative -top-[1px] remove-discount"
																title="Remove discount"
																onClick={() =>
																	handleRemoveDiscount(
																		cartId,
																		[...cartData.get().discountCodes],
																		discount.code
																	)
																}
															>
																&times;
															</span>
														</span>
													);
												}
											})}
										</div>
									) : null}
								</div>
								{cartStoreData &&
									cartStoreData.cost &&
									cartStoreData.cost.subtotalAmount &&
									cartStoreData.cost.subtotalAmount.amount !== "0.0" && (
										<Fragment>
											{cartTotals.linesTotal && cartTotals.linesTotal > 0 ? (
												<div className="subtotal-line flex justify-between mt-1">
													<span className="cart-subtotal">
														{cartTotals.subtotalBeforeEmbroidery &&
														cartTotals.subtotalBeforeEmbroidery > 0
															? "Subtotal Before Embroidery:"
															: "Subtotal:"}
													</span>{" "}
													<span className="subtotal-amount">
														$
														{cartTotals.subtotalBeforeEmbroidery &&
														cartTotals.subtotalBeforeEmbroidery > 0
															? cartTotals.subtotalBeforeEmbroidery
															: parseFloat(cartTotals.linesTotal).toFixed(2)}
													</span>
												</div>
											) : null}
											{cartTotals.embroideryTotal &&
											cartTotals.embroideryTotal >= 0 ? (
												<div className="subtotal-line flex justify-between mt-1">
													<span className="cart-subtotal">Embroidery:</span>{" "}
													<span className="subtotal-amount">
														${parseFloat(cartTotals.embroideryTotal).toFixed(2)}
													</span>
												</div>
											) : null}
											{cartTotals.subtotalBeforeEmbroidery &&
											cartTotals.subtotalBeforeEmbroidery >= 0 ? (
												<div className="subtotal-line flex justify-between mt-1">
													<span className="cart-subtotal">Subtotal:</span>{" "}
													<span className="subtotal-amount ">
														$ {parseFloat(cartTotals.linesTotal).toFixed(2)}
													</span>
												</div>
											) : null}
											{cartTotals.linesTotal !== cartTotals.subtotal ? (
												<div className="discount-line flex justify-between mt-1">
													<span className="cart-discount">Discount:</span>{" "}
													<span className="discount-amount text-flint">
														-$
														{parseFloat(
															cartTotals.linesTotal - cartTotals.subtotal
														).toFixed(2)}
													</span>
												</div>
											) : null}
											{cartTotals.linesTotal !== cartTotals.subtotal ? (
												<div className="total-line flex justify-between mt-1 mb-4">
													<span className="cart-subtotal">Total:</span>{" "}
													<span className="subtotal-amount">
														${parseFloat(cartTotals.subtotal).toFixed(2)}
													</span>
												</div>
											) : null}
										</Fragment>
									)}
								{cartStoreData &&
									cartStoreData.lines &&
									cartStoreData.lines.length > 0 && (
										<Fragment>
											<button
												id="checkout-button"
												className="focus:border-2 border-blue-400 bg-black text-white h-[42px] -full text-center flex items-center justify-center hover:bg-gray-800 mt-3 button-checkout"
												onClick={() => {
													handleCheckout(cartStoreData.checkoutUrl);
													let checkoutObj = {
														checkout: true,
													};
													checkoutData.set(checkoutObj);
												}}
											>
												Checkout
											</button>
											<div className="continue-shopping mt-2 flex items-center justify-center text-center">
												<span
													className="text-xs underline"
													onClick={() => isCartOpen.set(false)}
												>
													Continue Shopping
												</span>
											</div>
											{allClasses === false ? (
												<div className="gift-wrap-outer p-3 bg-ocean/50 mt-3">
													<div className="gift-wrap-inner flex flex-col relative">
														<label
															htmlFor="gift-wrap"
															className="flex items-center"
														>
															<input
																className="inline-block mr-2"
																id="gift-wrap"
																type="checkbox"
																onChange={(e) => handleGiftWrap(e)}
																checked={giftWrap}
															/>
															<span className="gift-icon w-4 block mr-2">
																<Gift width="w-4" />
															</span>
															<span className="gift-text inline-block">
																Add FREE Gift Wrap
															</span>
														</label>

														{cartStoreData && cartStoreData.giftWrap && (
															<span
																className="gift-note inline-block ml-auto text-xs underline cursor-pointer absolute right-1 top-1"
																onClick={() =>
																	setIsGiftNoteOpen(!isGiftNoteOpen)
																}
															>
																{isGiftNoteOpen &&
																cartStoreData.giftNote &&
																cartStoreData.giftNote !== ""
																	? "Hide Gift Note"
																	: `${
																			cartStoreData.giftNote &&
																			cartStoreData.giftNote !== ""
																				? "Edit"
																				: "Add"
																	  } Gift Note`}
															</span>
														)}

														{isGiftNoteOpen && (
															<div className="gift-wrap-note">
																<p className="text-sm my-1 text-stone-700">
																	Gift Note (optional):
																</p>
																<textarea
																	maxLength="140"
																	placeholder="Add a note for your gift"
																	className="bg-stone-100 border border-stone-200 rounded-sm px-2 h-24 w-full flex flex-start p-2 mb-3"
																	onChange={(e) => handleGiftNote(e)}
																	value={giftNote}
																/>

																<button
																	className={`w-full h-[42px] text-center flex items-center justify-center hover:bg-ash  hover:text-white ${
																		!cartStoreData.giftNote ||
																		cartStoreData.giftNote === ""
																			? "disabled cursor-not-allowed bg-slate"
																			: "bg-flint text-white"
																	}`}
																	onClick={() => {
																		if (giftNote) {
																			handleNoteSubmit(
																				cartId,
																				giftNote,
																				cartStoreData.giftWrap
																			);
																			setIsGiftNoteOpen(false);
																		}
																	}}
																>
																	{cartStoreData.giftNote ||
																	cartStoreData.giftNote !== ""
																		? "Save Gift Note"
																		: "Add Gift Note"}
																</button>
															</div>
														)}
													</div>
												</div>
											) : null}
										</Fragment>
									)}{" "}
							</div>
							{cartStoreData &&
							cartStoreData.lines &&
							cartStoreData.lines.length > 0 ? (
								<ul className="cart-items">
									{cartStoreData.lines.map((item, i) => {
										console.log('item in Cart.jsx', item)
										let product = item.node.merchandise.product;
										let productQuantity = item.node.quantity;
										let options = item.node.merchandise.selectedOptions;
										let attributes =
											item.node.attributes && item.node.attributes.length > 0
												? item.node.attributes
												: null;
										let hasEmbroidery =
											item.node.attributes &&
											item.node.attributes[1] &&
											item.node.attributes[1].key === "Add Embroidery?" &&
											item.node.attributes[1].value.indexOf("Yes") > -1
												? true
												: false;
										let subtotalAmount =
											item.node?.cost?.subtotalAmount?.amount;
										let totalAmount = item.node?.cost?.totalAmount?.amount;
										let discountedAmount = subtotalAmount - totalAmount;
										let discounts = item.node.discountAllocations;
										let isEventClass = product.tags.includes("class-event");
										return (
											<Fragment key={product.id + "_" + i}>
												{product.title.indexOf("Embroidery Option") === -1 ? (
													<li
														key={product.id + "_" + i}
														className="item flex py-2 border-t border-slate-100 flex-col"
													>
														<div className="item-wrap flex items-center">
															<span
																className="item-remove cursor-pointer w-8 flex items-center justify-center text-oat hover:text-flint"
																onClick={() =>
																	handleRemoveItem(
																		cartId,
																		item.node.id,
																		hasEmbroidery
																	)
																}
															>
																<ThickX width="w-[10px]" />
															</span>
															{product.featuredImage ? (
																<div className="item-image w-[48px] mr-2">
																	<img
																		src={product.featuredImage.url}
																		alt={product.featuredImage.altText}
																	/>
																</div>
															) : null}
															<div className="product-info text-xs w-[200px] flex flex-col">
																<span className="product-title">
																	{product.title.split("_")[0]}
																</span>
																<span className="product-options text-xs">
																	{options &&
																		options.map((o, i) => {
																			// console.log(
																			//     'o',
																			//     o
																			// )
																			if (
																				o.name !== "Gender" &&
																				o.value !== "Default Title"
																			) {
																				if (
																					i < options.length - 1 &&
																					i < options.length - 2
																				) {
																					return (
																						<span
																							className="mr-1"
																							key={o.value}
																						>
																							{o.value + "-" + i}
																							{" / "}
																						</span>
																					);
																				} else {
																					return (
																						<span
																							className="mr-1"
																							key={o.value + "-" + i}
																						>
																							{o.value}
																						</span>
																					);
																				}
																			}
																		})}
																</span>
															</div>
                                                            {product && product.title && product.title.indexOf('Free Organic Lounge Robe') === -1 ? (
                                                                <span className="item-quantity">
																<span
																	className={`quantity-decrement p-2 border-slate-100 bg-slate-50 ${
																		hasEmbroidery || isEventClass
																			? "disabled pointer-events-none opacity-30 cursor-not-allowed"
																			: "cursor-pointer"
																	}`}
																	onClick={() => {
																		if (isEventClass) {
																			nullFuction();
																		} else {
																			handleQuantityChange(
																				cartId,
																				item.node.id,
																				item.node.quantity,
																				"decrement"
																			);
																		}
																	}}
																>
																	-
																</span>
																<span className="quanity-amount bg-white borde-slate-100">
																	{productQuantity}
																</span>
																<span
																	className={`quantity-increment p-2 border-slate-100 bg-slate-50 ${
																		hasEmbroidery || isEventClass
																			? "disabled pointer-events-none opacity-30 cursor-not-allowed"
																			: "cursor-pointer"
																	}`}
																	onClick={() => {
																		if (isEventClass) {
																			nullFuction();
																		} else {
																			handleQuantityChange(
																				cartId,
																				item.node.id,
																				item.node.quantity,
																				"increment"
																			);
																		}
																	}}
																>
																	+
																</span>
															</span>
                                                            ) : null}

															<span className="product-price text-xs ml-auto">
																{subtotalAmount &&
																subtotalAmount !== totalAmount ? (
																	<span className="item-subtotal text-red-400 line-through mr-1">
																		($
																		{parseFloat(subtotalAmount).toFixed(2)})
																	</span>
																) : null}
																<span className="item-total">
																	${parseFloat(totalAmount).toFixed(2)}
																</span>
															</span>
														</div>

														{attributes && attributes.length > 0 ? (
															<div className={`product-attributes`}>
																<ul className="attributes-list">
																	{attributes.map((a, i) => {
                                                                        // console.log('a in attributes.map', a)
																		return a.key !== "_registry_item_id" && a.key !== "_pid" ? (
																			<li
																				key={a.key + "_" + i}
																				className="product-attribute"
																			>
																				<span className="attribute-key text-xs text-flint">
																					{a.key}:
																				</span>
																				<span className="attribute-value text-xs text-flint">
																					{" "}
																					{a.value}
																				</span>
																			</li>
																		) : null;
																	})}
																</ul>
															</div>
														) : null}

														{discounts && discounts.length > 0 ? (
															<div
																className={`product-discounts mt-2 ${
																	hasEmbroidery ? "" : "ml-[82px]"
																}`}
															>
																<ul className="discounts-list">
																	{discounts.map((d) => {
																		return (
																			<li
																				key={d.title}
																				className="discount-item text-[10px] text-flint"
																			>
																				Discount:{" "}
																				<span className="discount-title   italic">
																					{d.title
																						? d.title
																						: d.code
																						? d.code
																						: ""}
																				</span>{" "}
																				applied
																			</li>
																		);
																	})}
																</ul>
															</div>
														) : null}
													</li>
												) : null}
											</Fragment>
										);
									})}
								</ul>
							) : (
								<p className="text-sm text-center mx-auto">
									Nothing in your cart
								</p>
							)}{" "}
						</div>

                            <div className="free-product">
                                <FreeProduct />
                            </div>

						{/* FindMine cart widget container */}
						<div id="findmine-app-cart-container" className="xl:overflow-x-hidden"></div>
					</div>
				</div>
			</div>
		</aside>
	);
};

export default Cart;
